import React from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SDatepicker, SCol, SRow, SIcon } from "@avalara/skylab-react";
import {
    setFormState,
    selectFormState,
    fetchFailedAPIRecords,
    setIsExportFailedAPIDialogOpen,
} from "../../../app/failedAPIReportSlice";

function FailedAPIReportFilters() {
    const formState = useSelector(selectFormState, shallowEqual);
    const dispatch = useDispatch();
    const { fromDate } = formState;
    const { toDate } = formState;

    const handleInput = e => {
        dispatch(
            setFormState({
                ...formState,
                [e.detail.inputId]: e.detail.value.trim(),
            })
        );
        if (fromDate < toDate) {
            dispatch(fetchFailedAPIRecords());
        }
    };

    return (
        <React.Fragment>
            <SRow>
                <SCol span="8">
                    <SRow>
                        <SCol span="3">
                            <label htmlFor="fromDate">From</label>
                            <SDatepicker
                                inputid="fromDate"
                                className="width-auto"
                                onS-select={e => {
                                    handleInput(e);
                                }}
                                value={fromDate}
                                onS-deselect={e => {
                                    handleInput(e);
                                }}
                            />
                        </SCol>
                        <SCol span="3">
                            <label htmlFor="toDate">To</label>
                            <SDatepicker
                                inputid="toDate"
                                className="width-auto"
                                onS-select={e => {
                                    handleInput(e);
                                }}
                                value={toDate}
                                onS-deselect={e => {
                                    handleInput(e);
                                }}
                            />
                        </SCol>
                    </SRow>
                </SCol>
                <SCol span="4">
                    <span className="flex justify-content-end">
                        <button
                            type="button"
                            className="primary inline margin-left-lg margin-top-lg"
                            onClick={() => {
                                dispatch(setIsExportFailedAPIDialogOpen(true));
                            }}>
                            <SIcon name="export" className="margin-right-xs" aria-label="Export" />
                            Export
                        </button>
                    </span>
                </SCol>
            </SRow>
            <SRow />
        </React.Fragment>
    );
}

export default FailedAPIReportFilters;
