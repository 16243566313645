import { SCol, SRow, STabs } from "@avalara/skylab-react";
import React from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import featureFlag from "../../featureToggler/featureFlag";
import { isEligibleUser } from "../../shared/sessionUtility";

const ECMReportsHeader = React.memo(() => {
    const dispatch = useDispatch();
    const location = useLocation();
    const locationPath = location.pathname.toString();
    const navigate = useNavigate();
    const isEligibleUserForAPIReports = dispatch(
        isEligibleUser(featureFlag.reports.failedAPIReports)
    );
    let tabItems = [
        {
            id: "tabpanel-reports-certificatemanagementreports",
            path: "/reports/saved-searches",
            label: "Certificate management reports",
            tabPanelId: "tabpanel-reports-certificatemanagementreports",
            selected: locationPath.includes("/saved-searches"),
        },
    ];

    if (isEligibleUserForAPIReports) {
        tabItems = [
            ...tabItems,
            {
                id: "tabpanel-reports-adminreports",
                path: "/reports/failed-api-calls",
                label: "Admin reports",
                tabPanelId: "tabpanel-reports-adminreports",
                selected: locationPath.includes("/failed-api-calls"),
            },
        ];
    }

    const navigateTo = to => {
        // eslint-disable-next-line
        const currentHistory = locationPath;
        if (
            to.includes("tabpanel-reports-certificatemanagementreports") &&
            !currentHistory.includes("reports/saved-searches")
        ) {
            navigate(`/reports/saved-searches`);
        } else if (
            to.includes("tabpanel-reports-adminreports") &&
            !currentHistory.includes("reports/failed-api-calls")
        ) {
            navigate(`/reports/failed-api-calls`);
        }
    };

    const DOMelement = (
        <React.Fragment>
            <SRow>
                <SCol>
                    <h1 className="inline-block" id="pageHeader">
                        ECM Reports
                    </h1>
                </SCol>
            </SRow>
            <SRow>
                <SCol>
                    <React.Fragment>
                        <STabs tabItems={tabItems} onS-select={e => navigateTo(e.detail.id)} />
                        <Outlet />
                    </React.Fragment>
                </SCol>
            </SRow>
        </React.Fragment>
    );
    return DOMelement;
});

export default ECMReportsHeader;
