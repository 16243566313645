import { SCol, SIcon, SRow, STabs } from "@avalara/skylab-react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import featureFlag from "../../featureToggler/featureFlag";
import { essentialsOrAbove, isEligibleUser, vendorExemptions } from "../../shared/sessionUtility";
import { disableLink } from "../../shared/Utils";
import "./coverLetter.css";

const CoverLetterHeader = React.memo(() => {
    const location = useLocation();
    const locationPath = location.pathname.toString();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isEssentialsOrAbove = dispatch(essentialsOrAbove());
    const isVendorExemptions = dispatch(vendorExemptions());
    const vendorCheck = locationPath.includes("cover-letters/vendors");

    const bodyString = !vendorCheck
        ? "These are the templates that can be selected when sending a certificate request"
        : "These are the templates that can be selected when sending certificates to your vendors";
    const buttonLink = !vendorCheck ? "/add-cover-letter" : "/add-vendor-letter";

    const tabItems = [
        {
            id: "tabpanel-request-letters",
            label: "Request Templates",
            tabPanelId: "tabpanel-request-letters",
            selected: locationPath.includes("/cover-letters/requests"),
        },
        {
            id: "tabpanel-vendor-letters",
            label: `Vendor Templates`,
            tabPanelId: "tabpanel-vendor-letters",
            selected: locationPath.includes("/cover-letters/vendors"),
        },
    ];

    const isEligibleECMUserCoverLetterFlag = dispatch(
        isEligibleUser(featureFlag.requestCertificates.coverLetter)
    );

    const isEligibleECMUserCreateCoverLetterFlag = dispatch(
        isEligibleUser(featureFlag.requestCertificates.createCoverLetter)
    );
    const createTemplateButton = "add-template-btn";

    useEffect(() => {
        if (!isEligibleECMUserCreateCoverLetterFlag) {
            disableLink(createTemplateButton);
        }
    }, [isEligibleECMUserCreateCoverLetterFlag]);

    const navigateTo = to => {
        const currentHistory = locationPath;
        if (
            to.includes("tabpanel-request-letters") &&
            !currentHistory.includes("/cover-letters/requests")
        ) {
            navigate(`/cover-letters/requests`);
        } else if (
            to.includes("tabpanel-vendor-letters") &&
            !currentHistory.includes("/cover-letters/vendors")
        ) {
            navigate(`/cover-letters/vendors`);
        }
    };

    const headerDOM = (
        <React.Fragment>
            <SRow>
                <SCol>
                    <h1 className="inline-block">Manage templates</h1>
                </SCol>
            </SRow>
            <SRow>
                <SCol>
                    {isEssentialsOrAbove && isVendorExemptions ? (
                        <STabs
                            tabItems={tabItems}
                            onS-select={e => navigateTo(e.detail.id)}
                            className="margin-top-sm"
                        />
                    ) : (
                        ""
                    )}
                </SCol>
            </SRow>
            <SRow>
                <SCol>{bodyString}</SCol>
            </SRow>
            <SRow>
                <SCol class="flex justify-content-end">
                    <Link to={buttonLink} id={createTemplateButton}>
                        <button
                            disabled={!isEligibleECMUserCreateCoverLetterFlag}
                            aria-disabled={!isEligibleECMUserCreateCoverLetterFlag}
                            type="button"
                            className="primary inline">
                            <SIcon name="plus" className="margin-right-xs" aria-hidden="true" />
                            Create a template
                        </button>
                    </Link>
                </SCol>
            </SRow>
            <SRow>
                <SCol>
                    <Outlet />
                </SCol>
            </SRow>
            <SRow>
                <SCol>
                    <Link to="/settings/certificateRequests">
                        <button
                            disabled={!isEligibleECMUserCoverLetterFlag}
                            type="button"
                            data-testid="back-button"
                            className="secondary inline">
                            Back to settings
                        </button>
                    </Link>
                </SCol>
            </SRow>
        </React.Fragment>
    );

    return headerDOM;
});

export default CoverLetterHeader;
