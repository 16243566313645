import { SCol, SDialog, SIcon, SRow } from "@avalara/skylab-react";
import React, { useState, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import axios from "../../../axios";
import toast from "../../../hooks/toast";
import { fetchCategoriesAsync } from "../../../app/productCategoriesSlice";
import { errorTag, noErrorTag, matchSubscriptions, buildApiV3Url } from "../../../shared/Utils";
import { parseSuccessResponse } from "../../../shared/responseUtils";
import { avaTaxSubscription } from "../../navigation/Subscription";
import { selectSession } from "../../../app/sessionSlice";
import SelectAvataxCategories from "./SelectAvataxCategories";
import { CRUDOperation } from "../../../shared/constants";

function AddProductCategoryDialog(props) {
    const dialogName = "productCategoryDialog";
    const [showToast] = toast();
    const [categoryName, setCategoryName] = useState(null);
    const [categoryCodes, setCategoryCodes] = useState([]);
    const dispatch = useDispatch();
    const [categoryCodeError, setCategoryCodeError] = useState("");
    const [categoryNameError, setCategoryNameError] = useState("");
    const nameRef = useRef();
    const codeRef = useRef();
    const session = useSelector(selectSession, shallowEqual);
    const isAvaTax = matchSubscriptions(session.subscriptions, avaTaxSubscription);
    const handleCodesInput = txCode => {
        setCategoryCodeError("valid");
        const result = txCode.split(",");
        setCategoryCodes(result);
    };

    const handleCodesSelect = codeSel => {
        setCategoryCodes([...categoryCodes, codeSel.value]);
        setCategoryCodeError("valid");
    };

    const handleCodeRemove = codeSel => {
        setCategoryCodes(prevState => prevState.filter(prevItem => prevItem !== codeSel.value));
    };

    const buildCategoryData = () => {
        return {
            Category: categoryName,
            TaxCode: categoryCodes,
        };
    };

    const handleName = name => {
        setCategoryNameError("valid");
        setCategoryName(name);
    };

    const clearInputs = () => {
        if (nameRef.current) {
            nameRef.current.value = null;
        }
        if (!isAvaTax && codeRef.current) {
            codeRef.current.value = null;
        }
    };

    const saveExemptionCategory = async categoryData => {
        await axios
            .post(buildApiV3Url(`exempt-product-categories`), categoryData, {
                withCredentials: true,
            })
            .then(response => {
                if (parseSuccessResponse(CRUDOperation.Create, response)?.isSuccess) {
                    showToast(
                        "success",
                        "Category succesfully created",
                        "vendor-package-post-success",
                        true
                    );
                    props.openDialog(false);
                    dispatch(fetchCategoriesAsync());
                }
            })
            .catch(() => {
                showToast(
                    "error",
                    "It seems your category wasn't created. Try adding it again.",
                    "vendor-package-post-failure",
                    true
                );
                props.openDialog(false);
            });
    };

    const handleValidation = () => {
        let isValid = true;
        if (categoryName === "" || !categoryName) {
            setCategoryNameError("error");
            isValid = false;
        }
        if (!categoryCodes || categoryCodes.length < 1) {
            setCategoryCodeError("error");
            isValid = false;
        }
        return isValid;
    };

    const cancelHandler = () => {
        setCategoryNameError(null);
        setCategoryCodeError(null);
        setCategoryName(null);
        setCategoryCodes([]);
        props.openDialog(false);
        clearInputs();
    };

    const handleSubmission = async () => {
        if (handleValidation()) {
            const theData = buildCategoryData();
            await saveExemptionCategory(theData);
            setCategoryName(null);
            setCategoryCodes([]);
            setCategoryCodeError(null);
            setCategoryNameError(null);
            clearInputs();
        }
    };

    return (
        <SDialog
            id={dialogName}
            open={props.isDialogOpen}
            aria-modal="true"
            className="overflow-visible"
            onS-dismiss={() => {
                props.openDialog(false);
            }}>
            <div slot="header" id="addCategory">
                Add a category
            </div>
            <div slot="body" className="category-modal-body">
                <SRow className="margin-bottom-sm">
                    <SCol>
                        <span className="block">
                            Create a product category and add it to the certificates when only
                            <br /> specific products are considered exempt.
                        </span>
                    </SCol>
                </SRow>
                <SRow>
                    <SCol>
                        <label className="required"> Give this category a name </label>
                        <input
                            ref={nameRef}
                            type="text"
                            onChange={e => handleName(e.target.value)}
                            className={categoryNameError === "error" ? errorTag : noErrorTag}
                        />
                        {categoryNameError === "error" ? (
                            <div className="input-msg">
                                <SIcon name="alert-circle-filled" aria-hidden="true" />
                                <span className="top-xs">Enter a name </span>
                            </div>
                        ) : null}
                    </SCol>
                    <SCol className="margin-top-sm">
                        <SelectAvataxCategories
                            onAdd={handleCodesSelect}
                            onRemove={handleCodeRemove}
                            codeError={categoryCodeError}
                            value={categoryCodes}
                            onInput={handleCodesInput}
                            avaCheck={isAvaTax}
                            codeRef={codeRef}
                        />
                    </SCol>
                </SRow>
            </div>
            <div slot="footer">
                <button
                    className="primary large dialog-footer-button"
                    onClick={() => {
                        handleSubmission();
                    }}>
                    Add
                </button>
                <button
                    className="secondary large dialog-footer-button"
                    onClick={() => {
                        cancelHandler();
                    }}>
                    Cancel
                </button>
            </div>
        </SDialog>
    );
}

export default AddProductCategoryDialog;
