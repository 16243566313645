import React, { useCallback, useState } from "react";
import { SSelect, SRow, SCol, SIcon } from "@avalara/skylab-react";
import { debounce } from "lodash";
import axios from "../../axios";
import { fixSearchStringForPostgres, buildApiV3Url } from "../../shared/Utils";
import useDeepCompareCallback from "../../hooks/useDeepCompareCallback";
import useDeepCompareEffect from "../../hooks/useDeepCompareEffect";

const CustomerTabTypeAhead = React.memo(props => {
    const [customers, setCustomers] = useState([]);
    const [optionsList, setOptionsList] = useState([]);
    const [loading, setLoading] = useState(false);

    const {
        typeAheadLabel,
        selectRef,
        customerError,
        customerId,
        sameAsCompany,
        sameAs,
        value,
        error,
        handleAdd,
        handleRemove,
    } = props;

    const fetchTypeAhead = useCallback(
        async typeAheadValue => {
            const fixedValue = fixSearchStringForPostgres(typeAheadValue);

            const typeAheadURL = !sameAs
                ? buildApiV3Url(
                      `customers/typeahead?$filter=name contains '${fixedValue}' or customerNumber contains '${fixedValue}'  &$orderBy=name ASC&$top=10`
                  )
                : buildApiV3Url(
                      `customers/${customerId}/not-same-as-customers/${sameAsCompany}?$filter=name contains '${fixedValue}' or customerNumber contains '${fixedValue}'  &$orderBy=name ASC&$top=10`
                  );

            const response = await axios.get(typeAheadURL, {
                withCredentials: true,
            });

            if (sameAs) {
                return response.data.value;
            }
            return response.data;
        },
        [customerId, sameAsCompany, sameAs]
    );

    const customerLink = useDeepCompareCallback(
        event => {
            if (
                event &&
                event.path &&
                event.path.length >= 1 &&
                event.path[1].nodeName === "S-CHIP"
            ) {
                const idRegex = event.path[1].id.match(/-(.*)-/);
                if (idRegex && idRegex.length >= 1) {
                    const id = parseInt(idRegex[1], 10);
                    const idIndex = customers.findIndex(element => element.id === id);
                    if (idIndex !== -1) {
                        window.open(`${window.location.origin}/customer/${id}`, "_blank");
                    }
                }
            }
        },
        [customers]
    );

    useDeepCompareEffect(() => {
        if (value && (!customers?.length || value?.length > customers?.length)) {
            setCustomers(value);
            setOptionsList(
                value.map(element => {
                    return {
                        label: `${element.name ? element.name : element.customerName} : ${
                            element.customerNumber
                        }`,
                        value: `${element.id}`,
                        selected: true,
                    };
                })
            );
        }
    }, [value, customers.length]);

    const uniqueCustomer = useCallback((prevCustomer, newCustomer) => {
        if (!prevCustomer.length) return newCustomer;
        if (!newCustomer.length) return prevCustomer;

        const prevCustomerIds = prevCustomer.map(ele => ele.id);
        return [
            ...prevCustomer,
            ...newCustomer.filter(cust => prevCustomerIds.indexOf(cust.id) === -1),
        ];
    }, []);

    const typeAhead = debounce(async e => {
        let typeAheadResults = {};
        if (e.detail.inputValue !== "" && e.detail.inputValue.length > 1 && !loading) {
            setLoading(true);
            const results = await fetchTypeAhead(e.detail.inputValue);

            if (results) {
                typeAheadResults = results.map(element => {
                    return {
                        label: `${element.name ? element.name : element.customerName} : ${
                            element.customerNumber
                        }`,
                        value: `${element.id}`,
                    };
                });

                setOptionsList(typeAheadResults);
                setCustomers(uniqueCustomer(customers, results));
            }
            setLoading(false);
        }
    }, 500);

    const onSelect = useCallback(
        e =>
            handleAdd(
                customers.filter(customer => String(customer.id) === String(e.detail.item.value))
            ),
        [customers, handleAdd]
    );

    const onDeselect = useCallback(e => handleRemove(e.detail.item), [handleRemove]);

    return (
        <SRow>
            <SCol>
                <label id="lbl-async-select" htmlFor="async-select-input" className="required">
                    {typeAheadLabel}
                </label>
                <SSelect
                    id="async-customer-tab-select"
                    loading={loading}
                    ref={selectRef}
                    inputId="async-select-input"
                    aria-labelledby="lbl-async-select"
                    className={error ?? "text-as-link"}
                    async
                    selectionOptional
                    placeholder="Search customers..."
                    optionsList={optionsList}
                    onS-select={onSelect}
                    onS-deselect={onDeselect}
                    onS-input={typeAhead}
                    onS-open={customerLink}
                />
                <div className="input-msg" hidden={!customerError}>
                    <SIcon name="alert-circle-filled" aria-hidden="true" />
                    <span className="top-xs">Enter a customer</span>
                </div>
            </SCol>
        </SRow>
    );
});

export default CustomerTabTypeAhead;
