import React, { useState, useEffect } from "react";
import { SDialog, SRow, SCol } from "@avalara/skylab-react";
import "./failedAPIReport.css";

function ViewErrorDialog(props) {
    const handleCloseDialog = () => {
        props.setIsViewErrorDialogOpen(false);
    };
    const [errorMessage, setErrorMessage] = useState(props.errorDetails.errorMessage);

    useEffect(() => {
        const errorMessageReceived = props.errorDetails.errorMessage;
        const startChar = "Received response: ";
        const endChar = "API Name:";

        try {
            const start = errorMessageReceived.indexOf(startChar);
            const end = errorMessageReceived.indexOf(endChar, start);

            if (start > 0 && end > start) {
                const receivedResponse = errorMessageReceived.substring(start, end).trim();
                const modifiedErrorMessage = receivedResponse.slice(
                    receivedResponse.indexOf(":") + 1
                );
                setErrorMessage(modifiedErrorMessage);
            } else {
                setErrorMessage(errorMessageReceived); // Use original if no delimiters found
            }
        } catch (error) {
            setErrorMessage(errorMessageReceived); // Fallback to original message
        }
    }, [props.errorDetails.errorMessage]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <SDialog
            open={props.isViewErrorDialogOpen}
            id="view-error-dialog"
            onS-dismiss={handleCloseDialog}
            className="view-error-dialog-width"
            aria-modal="true">
            <div slot="header" id="errorDetailsHeader" className="inline">
                <span>Error details</span>
            </div>
            <div slot="body">
                <SRow>
                    <SCol span="5" class="font-bold">
                        <span className="margin-left-xs">
                            API call initiated for certificate {props.errorDetails.entityId}
                        </span>
                    </SCol>
                    <SCol span="7">
                        <span id="invalid-reason-name">Failure</span>
                    </SCol>
                </SRow>
                <SRow>
                    <SCol span="5" class="font-bold">
                        <span className="margin-left-xs">Received Response</span>
                    </SCol>
                    <SCol span="7">
                        <span id="error-message">{errorMessage}</span>
                    </SCol>
                </SRow>
                <SRow>
                    <SCol span="5" class="font-bold">
                        <span className="margin-left-xs">API name</span>
                    </SCol>
                    <SCol span="7">
                        <span id="api-name">{props.errorDetails.apiName}</span>
                    </SCol>
                </SRow>
            </div>
            <div slot="footer">
                <button className="tertiary" onClick={handleCloseDialog}>
                    Cancel
                </button>
                {/* <button
                    id="save-InvalidReason-button"
                    className={isLoading ? "primary right loading" : "primary right"}
                    onClick={handleSave}
                    disabled={!isEligibleUserSettingsAddOrEditInvalidReason}>
                    Save
                </button> */}
            </div>
        </SDialog>
    );
}

export default ViewErrorDialog;
