import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SLoader, SPagination } from "@avalara/skylab-react";
import { gridHeader, DataGrid } from "@certcapture/react-components";
import { v4 as uuidv4 } from "uuid";
import {
    fetchFailedAPIRecords,
    selectPage,
    selectIsLoading,
    setIsLoading,
    setPageToNull,
} from "../../../app/failedAPIReportSlice";
import RecordCount from "../../search/RecordCount";
import { selectSession, updateRowCountPreference } from "../../../app/sessionSlice";
import { getRowsPerPage } from "../../../shared/Utils";
import ViewErrorDialog from "./ViewErrorDialog";

export const loaderText = "Loading ...";
export const noData = "No records found.";
export const countLabel = "records";

function FailedAPIReportGrid() {
    const dispatch = useDispatch();
    const session = useSelector(selectSession, shallowEqual);
    const page = useSelector(selectPage, shallowEqual);
    const gridData = page.data;
    const pageData = page.paginate;
    const isLoading = useSelector(selectIsLoading);
    const [isViewErrorDialogOpen, setIsViewErrorDialogOpen] = useState(false);
    const [currentRow, setCurrentRow] = useState({});
    useEffect(() => {
        dispatch(fetchFailedAPIRecords());
        return () => {
            dispatch(setPageToNull([]));
        };
    }, [dispatch]);

    const openViewErrorDialog = row => {
        setCurrentRow(row);
        setIsViewErrorDialogOpen(true);
    };

    const detailTable = customersDetails => {
        if (!customersDetails || customersDetails.length === 0) {
            return <div className="text-sm-strong">No data found.</div>;
        }
        return (
            <s-table-container>
                <table className="dg-data-grid nested-grid">
                    <thead>
                        <tr key={uuidv4()}>
                            <th>Customer number</th>
                            <th style={{ width: "200px" }}>Customer name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {customersDetails.map(x => (
                            <tr id={`${x.customerId}`} key={`${x.customerId}`}>
                                <td>{x.customerId}</td>
                                <td style={{ width: "200px" }}>{x.customerName}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </s-table-container>
        );
    };

    const columns = [
        gridHeader("Record ID", "entityId", value => value, null, "", null, null, null, "entityId"),
        gridHeader(
            "Record type",
            "entityType",
            value => value,
            null,
            "",
            null,
            null,
            null,
            "entityType"
        ),
        gridHeader(
            "Region",
            "exposureZoneName",
            value => value,
            null,
            "",
            null,
            null,
            null,
            "exposureZoneName"
        ),
        gridHeader(
            "Tax code",
            "taxCodeName",
            value => value,
            null,
            "",
            null,
            null,
            null,
            "taxCodeName"
        ),
        gridHeader("Api name", "apiName", value => value, null, "", null, null, null, "apiName"),
        gridHeader(
            "First time",
            "firstOccurence",
            value => new Date(value).toLocaleDateString(),
            null,
            "",
            null,
            null,
            null,
            "firstOccurence"
        ),
        gridHeader(
            "Last time",
            "lastOccurence",
            value => new Date(value).toLocaleDateString(),
            null,
            "",
            null,
            null,
            null,
            "lastOccurence"
        ),
        gridHeader(
            "",
            "error",
            (value, row) => (
                <button
                    id={`view-button-${row.entityId}`}
                    className={
                        row.systemCode ? "link font-semibold" : "link font-semibold color-disabled"
                    }
                    onClick={() => openViewErrorDialog(row)}>
                    View
                </button>
            ),
            "text-center",
            "text-center",
            null,
            (value, row) => detailTable(row.relatedCustomersData),
            null,
            "error"
        ),
    ];

    const getRowKey = row => {
        return row.id;
    };

    const handlePaginate = async e => {
        if (e.detail.currentPage !== 0 && pageData.totalRecords > 0 && e.detail.flag !== "") {
            setIsLoading(true);
            if (e.detail.rowsPerPage !== session.rowsPerPage) {
                dispatch(updateRowCountPreference(e.detail.rowsPerPage));
            }
            dispatch(fetchFailedAPIRecords(e.detail.rowsPerPage, e.detail.startIndex, e.detail));
            setIsLoading(false);
        }
    };

    const table = isLoading ? (
        <div className="flex dl-flex-fill-height dl-flex-center">
            <h3> {loaderText}</h3>
            <SLoader id="page-loader" className="medium" aria-live="polite" loading />
        </div>
    ) : (
        <s-table-container>
            <DataGrid
                columns={columns}
                rows={gridData}
                getKey={getRowKey}
                loading={isLoading}
                rowExpander
                inContainer
            />
        </s-table-container>
    );

    return !isLoading && pageData.totalRecords === 0 ? (
        <div className="text-center flex dl-flex-fill-height dl-flex-center">
            <h1 className="text-color-no-data">{noData}</h1>
            <p className="text-color-no-data">Adjust your search and try again</p>
        </div>
    ) : (
        <React.Fragment>
            <RecordCount
                selectedCount={0}
                recordCount={pageData.totalRecords}
                recordName={countLabel}
            />
            {table}
            {pageData.totalRecords > 0 ? (
                <SPagination
                    className="margin-top-md"
                    rowsPerPage={getRowsPerPage(pageData, session.rowsPerPage)}
                    totalRecords={pageData.totalRecords}
                    startIndex={pageData.startIndex}
                    onS-paginate={e => {
                        handlePaginate(e);
                    }}
                />
            ) : null}

            {isViewErrorDialogOpen ? (
                <ViewErrorDialog
                    isViewErrorDialogOpen={isViewErrorDialogOpen}
                    setIsViewErrorDialogOpen={setIsViewErrorDialogOpen}
                    errorDetails={currentRow}
                />
            ) : null}
        </React.Fragment>
    );
}
FailedAPIReportGrid.displayName = "FailedAPIReportGrid";
export default React.memo(FailedAPIReportGrid);
