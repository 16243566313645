import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { SRow, SCol, SIcon } from "@avalara/skylab-react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getPageTitle, isEligibleUser } from "../../../shared/sessionUtility";
import InvalidReasonsGrid from "./InvalidReasonsGrid";
import InvalidReasonsFilter from "./InvalidReasonsFilter";
import FeatureToggler from "../../../featureToggler/FeatureToggler";
import featureFlag from "../../../featureToggler/featureFlag";

export const header = "Invalid reasons";

const ManageInvalidReasons = React.memo(() => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [addInvalidReasonDialog, setAddInvalidReasonDialog] = useState(false);
    const [addOrEdit, setAddOrEdit] = useState("");
    const handleAddInvalidReason = () => {
        setAddInvalidReasonDialog(true);
        setAddOrEdit("add");
    };
    const isEligibleUserSettingsAddInvalidReason = dispatch(
        isEligibleUser(featureFlag.settings.addInvalidReason)
    );

    return (
        <React.Fragment>
            <Helmet>
                <title>{dispatch(getPageTitle("Invalid reasons"))}</title>
            </Helmet>
            <div>
                <h1 id="page-header" className="line-height-md margin-bottom-sm">
                    {header}
                </h1>
                <p data-testid="page-info">
                    View and manage the reasons that are available when saving a certificate as
                    invalid.
                </p>

                <SRow className="align-items-end pad-bottom-sm">
                    <SCol span="8">
                        <SRow>
                            <SCol span="md-6 xl-6 5" className="pad-bottom-none">
                                <InvalidReasonsFilter />
                            </SCol>
                        </SRow>
                    </SCol>
                    <FeatureToggler category="settings" id="addInvalidReason">
                        <SCol span="4">
                            <span className="flex justify-content-end">
                                <button
                                    id="add-invalidReason-button"
                                    data-testid="add-invalid-reason-button"
                                    className="primary inline margin-left-sm"
                                    onClick={() => handleAddInvalidReason()}
                                    disabled={!isEligibleUserSettingsAddInvalidReason}>
                                    <SIcon
                                        name="plus"
                                        className="margin-right-xs"
                                        aria-label="Add invalid reason"
                                    />
                                    Add invalid reason
                                </button>
                            </span>
                        </SCol>
                    </FeatureToggler>
                </SRow>
            </div>
            <FeatureToggler category="settings" id="viewInvalidReason">
                <InvalidReasonsGrid
                    addInvalidReasonDialog={addInvalidReasonDialog}
                    setAddInvalidReasonDialog={setAddInvalidReasonDialog}
                    addOrEdit={addOrEdit}
                    setAddOrEdit={setAddOrEdit}
                />
            </FeatureToggler>

            <span className="flex justify-content-start">
                <button
                    className="secondary"
                    type="button"
                    data-testid="back-button"
                    onClick={() => {
                        navigate(`/settings/advanced-settings`);
                    }}>
                    Back to settings
                </button>
            </span>
        </React.Fragment>
    );
});

export default ManageInvalidReasons;
