import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { SRow, SCol, SIcon } from "@avalara/skylab-react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getPageTitle, isEligibleUser } from "../../../shared/sessionUtility";
import ExemptionReasonsGrid from "./ExemptionReasonsGrid";
import ExemptionReasonsFilter from "./ExemptionReasonsFilter";
import FeatureToggler from "../../../featureToggler/FeatureToggler";
import featureFlag from "../../../featureToggler/featureFlag";
import "./manageExemptionReasonsStyle.css";

function ManageExemptionReasons() {
    const [openAddUpdateDialog, setOpenAddUpdateDialog] = useState(false);
    const [addOrEdit, setAddOrEdit] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isEligibleUserSettingsAddExemptionReason = dispatch(
        isEligibleUser(featureFlag.settings.addExemptionReason)
    );

    const handleAddExemption = () => {
        setOpenAddUpdateDialog(true);
        setAddOrEdit("add");
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{dispatch(getPageTitle("Exempt reasons"))}</title>
            </Helmet>
            <div>
                <h1 id="page-header" className="line-height-md margin-bottom-sm">
                    Exempt reasons
                </h1>
                <p>
                    View and manage the reasons that are available when saving a certificate as
                    valid.
                </p>

                <SRow className="align-items-end pad-bottom-sm">
                    <SCol span="8">
                        <SRow>
                            <SCol span="md-6 xl-6 5" className="pad-bottom-none">
                                <ExemptionReasonsFilter />
                            </SCol>
                        </SRow>
                    </SCol>
                    <FeatureToggler category="settings" id="addExemptionReason">
                        <SCol span="4">
                            <span className="flex justify-content-end">
                                <button
                                    className="primary inline margin-left-sm"
                                    onClick={() => handleAddExemption()}
                                    disabled={!isEligibleUserSettingsAddExemptionReason}>
                                    <SIcon
                                        name="plus"
                                        className="margin-right-xs"
                                        aria-label="Add exempt reason"
                                    />
                                    Add exempt reason
                                </button>
                            </span>
                        </SCol>
                    </FeatureToggler>
                </SRow>
            </div>
            <FeatureToggler category="settings" id="viewExemptionReason">
                <ExemptionReasonsGrid
                    openAddUpdateDialog={openAddUpdateDialog}
                    setOpenAddUpdateDialog={setOpenAddUpdateDialog}
                    addOrEdit={addOrEdit}
                    setAddOrEdit={setAddOrEdit}
                />
            </FeatureToggler>

            <span className="flex justify-content-start">
                <button
                    className="secondary"
                    type="button"
                    data-testid="back-button"
                    onClick={() => {
                        navigate(`/settings/advanced-settings`);
                    }}>
                    Back to settings
                </button>
            </span>
        </React.Fragment>
    );
}

export default ManageExemptionReasons;
