import React from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { getPageTitle } from "../../../shared/sessionUtility";
import FailedAPIReportGrid from "./FailedAPIReportGrid";
import FailedAPIReportFilters from "./FailedAPIReportFilters";
import FailedAPIExportDialog from "./FailedAPIExportDialog";
import { selectIsExportFailedAPIDialogOpen } from "../../../app/failedAPIReportSlice";

const FailedAPIReport = React.memo(() => {
    const dispatch = useDispatch();
    return (
        <React.Fragment>
            <Helmet>
                <title>{dispatch(getPageTitle("Failed API Reports"))}</title>
            </Helmet>
            <h2 id="page-header" className="line-height-md margin-bottom-sm">
                API Errors
            </h2>
            <p>
                View errors that occur between ECM and other APIs. Select an entity to view it and
                correct any issues. Fixed errors wont appear on this page.
            </p>
            <FailedAPIReportFilters />
            <FailedAPIReportGrid />
            {selectIsExportFailedAPIDialogOpen ? <FailedAPIExportDialog /> : null}
        </React.Fragment>
    );
});

export default FailedAPIReport;
