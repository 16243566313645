import React from "react";
import { STabs } from "@avalara/skylab-react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";

const CustomFields = React.memo(() => {
    const location = useLocation();
    const locationPath = location.pathname.toString();
    const navigate = useNavigate();

    const tabItems = [
        {
            id: "tabpanel-settings-CustomFields-CertificatesCustomFields",
            path: "/settings/custom-fields/certificates-custom-fields",
            label: "Certificates",
            tabPanelId: "tabpanel-settings-CustomFields-CertificatesCustomFields",
            selected: locationPath.includes("certificates-custom-fields"),
        },
        {
            id: "tabpanel-settings-CustomFields-CustomersCustomFields",
            path: "/settings/custom-fields/customers-custom-fields",
            label: "Customers",
            tabPanelId: "tabpanel-settings-CustomFields-CustomersCustomFields",
            selected: locationPath.includes("customers-custom-fields"),
        },
    ];

    const navigateTo = to => {
        // eslint-disable-next-line
        const currentHistory = locationPath;
        if (
            to.includes("tabpanel-settings-CustomFields-CertificatesCustomFields") &&
            !currentHistory.includes("custom-fields/certificates-custom-field")
        ) {
            navigate(`/settings/custom-fields/certificates-custom-fields`);
        } else if (
            to.includes("tabpanel-settings-CustomFields-CustomersCustomFields") &&
            !currentHistory.includes("custom-fields/customers-custom-fields")
        ) {
            navigate(`/settings/custom-fields/customers-custom-fields`);
        }
    };

    const DOMelement = (
        <React.Fragment>
            <h1 id="page-header"> Custom fields</h1>
            <STabs tabItems={tabItems} onS-select={e => navigateTo(e.detail.id)} />
            <Outlet />
        </React.Fragment>
    );
    return (
        <React.Fragment>
            {DOMelement}
            <span className="flex justify-content-start">
                <button
                    className="secondary"
                    type="button"
                    data-testid="back-button"
                    onClick={() => {
                        navigate(`/settings/advanced-settings`);
                    }}>
                    Back to settings
                </button>
            </span>
        </React.Fragment>
    );
});

export default CustomFields;
